import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Pagination from "../components/Pagination";
import CertificatesView from "../components/CertificatesView";
import Sectiontitle from "../components/Sectiontitle";
import Spinner from "../components/Spinner";
const { Api } = require('../variables');

function Certificates() {
  const [certificates, setCertificates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [certificatesPerPage] = useState(6);

  useEffect(() => {
    let mounted = true;
    axios.get(`${Api.path}/api/v1/certificates`).then((response) => {
      if (mounted) {
        setCertificates(response.data);
      }
    });
    return () => (mounted = false);
  }, []);

  const indexOfLastCertificates = currentPage * certificatesPerPage;
  const indexOfFirstCertificates = indexOfLastCertificates - certificatesPerPage;
  const currentCertificates = certificates.slice(
    indexOfFirstCertificates,
    indexOfLastCertificates
  );

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <Helmet>
        <title>Certificates - Stefan Konakchiev React Personal Portfolio Template</title>
        <meta
          name="description"
          content="Stefan Konakchiev React Personal Portfolio Template Portfolios Page"
        />
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
          <div className="container">
            <Sectiontitle title="Certificates" />
            {<CertificatesView certificates={currentCertificates} />}
            {!(certificates.length > certificatesPerPage) ? null : (
              <Pagination
                className="mt-50"
                itemsPerPage={certificatesPerPage}
                totalItems={certificates.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
      </Suspense>
    </Layout>
  );
}

export default Certificates;

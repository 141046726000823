import Mock from "../mock";

const database = {
  information: {
    name: 'Stefan Konakchiev',
    aboutContent: "I am a software developer. I am always keen to learn new skills and my interests lie in expanding my knowledge in the software field.",
    birthday: "June 19, 1986 12:00:00",
    phone: '',
    nationality: 'Bulgarian',
    language: 'English, Bulgarian',
    email: '',
    address: 'Lulin 10, 151, Sofia, Bulgaria',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: 'https://facebook.com',
      twitter: 'https://twitter.com',
      pinterest: '',
      behance: '',
      linkedin: '',
      dribbble: '',
      github: 'https://github.com'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/empty.pdf'
  },
  services: [
    {
      title: "Web Design",
      icon: 'brush-alt',
      details: "Web design is the field that involves the creation of digital interfaces such as websites, apps and services."
    },
    {
      title: "Web Development",
      icon: 'code',
      details: "Involves building and maintenance! It is the work that happens behind the scenes to make a website look great."
    },
    {
      title: "Mobile Application",
      icon: 'mobile',
      details: "Includes writing software for small, wireless computing devices, such as smartphones."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Great co-worker and team member. Stefan is not only well knowledged on the subjects related to work but also on variety subjects outside work. That`s why it is always pleasant to have a conversation with him. He does`t have a problem to admit his mistakes which is great quality in our line of work.",
      author: {
        name: 'Dimitar Nikolov',
        designation: 'Software Engineer'
      }
    },
    {
      id: 2,
      content: "Stefan has been doing a great job lately. He is talanted, easy to work with and flexible on any project, which overall helps the team.",
      author: {
        name: 'Yordan Aleksiev',
        designation: 'Web Developer'
      }
    }
  ],
  skills: [
    {
      title: "C#",
      value: 85
    },
    {
      title: "Javascript",
      value: 70
    },
    {
      title: "SQL",
      value: 60
    },
    {
      title: "ReactJS",
      value: 65
    },
    {
      title: "CSS",
      value: 50
    }
  ],
  certificates: [
    {
      id: 1,
      title: "C# Diploma",
      subtitle: "December 2021",
      imageUrl: "/images/diploma-c-sharp.jpg",
      largeImageUrl: ["/images/diploma-c-sharp-lg.jpg"]
    },
    {
      id: 2,
      title: "C# OOP Basics",
      subtitle: "October 2018",
      imageUrl: "/images/c-sharp-oop-basics.jpg",
      largeImageUrl: ["/images/c-sharp-oop-basics-lg.jpg"]
    },
    {
      id: 3,
      title: "C# OOP Advanced",
      subtitle: "November 2018",
      imageUrl: "/images/c-sharp-oop-advanced.jpg",
      largeImageUrl: ["/images/c-sharp-oop-advanced-lg.jpg"]
    },
    {
      id: 4,
      title: "C# Web Basics",
      subtitle: "May 2019",
      imageUrl: "/images/c-sharp-web-basics.jpg",
      largeImageUrl: ["/images/c-sharp-web-basics-lg.jpg"]
    },
    {
      id: 5,
      title: "C# Advanced",
      subtitle: "September 2018",
      imageUrl: "/images/c-sharp-advanced.jpg",
      largeImageUrl: ["/images/c-sharp-advanced-lg.jpg"]
    },
    {
      id: 6,
      title: "ASP .NET Core MVC",
      subtitle: "June 2019",
      imageUrl: "/images/asp-dotnet-core-mvc.jpg",
      largeImageUrl: ["/images/asp-dotnet-core-mvc-lg.jpg"]
    },
    {
      id: 7,
      title: "Database Basics",
      subtitle: "January 2019",
      imageUrl: "/images/database-basics.jpg",
      largeImageUrl: ["/images/database-basics-lg.jpg"]
    },
    {
      id: 8,
      title: "Database Advanced",
      subtitle: "February 2019",
      imageUrl: "/images/database-advanced.jpg",
      largeImageUrl: ["/images/database-advanced-lg.jpg"]
    },
    {
      id: 9,
      title: "Fundamentals of Programming",
      subtitle: "March 2018",
      imageUrl: "/images/fundamentals-of-programming.jpg",
      largeImageUrl: ["/images/fundamentals-of-programming-lg.jpg"]
    },
    {
      id: 10,
      title: "JS Advanced",
      subtitle: "September 2019",
      imageUrl: "/images/js-advanced.jpg",
      largeImageUrl: ["/images/js-advanced-lg.jpg"]
    },
    {
      id: 11,
      title: "JS Applications",
      subtitle: "October 2019",
      imageUrl: "/images/js-applications.jpg",
      largeImageUrl: ["/images/js-applications-lg.jpg"]
    },
    {
      id: 12,
      title: "Programming Fundamentals",
      subtitle: "May 2018",
      imageUrl: "/images/programming-fundamentals.jpg",
      largeImageUrl: ["/images/programming-fundamentals-lg.jpg"]
    },
    {
      id: 13,
      title: "Software Technologies",
      subtitle: "July 2018",
      imageUrl: "/images/software-technologies.jpg",
      largeImageUrl: ["/images/software-technologies-lg.jpg"]
    },
    {
      id: 14,
      title: "Programming Basics",
      subtitle: "January 2018",
      imageUrl: "/images/programming-basics.jpg",
      largeImageUrl: ["/images/programming-basics-lg.jpg"]
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2012 - 2014",
        position: "Graduate Development Engineer",
        company: "Delphi Diesel Systems, London",
        details: "Assuming responsibility for the work operations and specifications of six validation and structural rigs. Assisting validation engineers with running structural and wear tests at the appropriate conditions. Managing product features according to purpose of testing. Planning and implementing tests, thus managing project operations."
      },
      {
        id: 2,
        year: "2014 - 2015",
        position: "Graduate Analysis Engineer",
        company: "Delphi Diesel Systems, London",
        details: "Complete structural design/analysis activities as identified by structural analysis team leader considering design strength, material selection and manufacturing processes. Support structural analysis activities of lead structural analysis engineer in the latest injector products including all customer variants."
      },
      {
        id: 3,
        year: "2017 - 2019",
        position: "Analysis Engineer",
        company: "Sensata Technolgies, Sofia",
        details: "Perform structural, thermal, vibration, electric and explicit design/analysis activities on different products and concepts using ANSYS software. Generate analysis reports according to the defined template. Report conclusions against established thresholds."
      },
      {
        id: 4,
        year: "2017 - 2019",
        position: "Software Engineer",
        company: "UltraPlay, Sofia",
        details: "Planning, estimation, development and delivery of projects with high complexity. Responsible for building a wide range of products including, but not limited to, casinos, monitoring system API, payment operators integration. Working closely with high profile clients during project integrations. Main responsibilities lie within the .NET field as a backend developer, however frontend tasks were also performed on a non-uniform basis using JavaScript/React (with Redux)."
      },
      {
        id: 5,
        year: "2021 - 2022",
        position: "Software Engineer",
        company: "GFK, Sofia",
        details: "Responsible for building APIs and projects to deal with wide range of data ingestion. Designing project solutions and working with continuous integration and continuous delivery oriented structure towards their execution. Main responsibilities lie within the .NET field as a backend developer"
      },
      {
        id: 6,
        year: "2022 - Present",
        position: "Software Engineer",
        company: "DraftKings, Sofia",
        details: "Building and refactoring of APIs and projects involved with business layer logic. Reverse engineering and re-writing obsolete projects. Main responsibilities lie within the .NET field as a backend developer"
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2000 - 2005",
        graduation: "Computer Systems",
        university: "Professional School of Electronics “John Atanasov”, Sofia",
        details: "Computer Software, Computer Hardware, Acquiring theoretical knowledge and practical techniques in computer systems."
      },
      {
        id: 2,
        year: "2005 - 2010",
        graduation: "Bachelor of Science, Mechanical Engineering",
        university: "Technical University of Sofia, Sofia",
        details: "Analysis, Design and manufacturing of mechanical systems, Statics and Dynamics, Mechanics of Materials, Logistics, Computer-Aided Design, Fluid Mechanics."
      },
      {
        id: 3,
        year: "2010 - 2011",
        graduation: "Master of Science, Mechanical Engineering",
        university: "Birmingham City University, Birmingham UK",
        details: "Automotive Powertrain, Body Engineering, Computer-Aided Design, Simulation Techniques, Dynamics, Noise and Control, New Materials and Processes."
      },
      {
        id: 4,
        year: "2017 - 2019",
        graduation: "C# Web Development",
        university: "SoftUni, Sofia",
        details: "C# OOP - classes, objects, abstraction, design patterns. C# Avanced - stacks, queues, arrays, streams, files. Databases (MS SQL, Entity Framework Core) - CRUD, data aggregation, table relations, code-first, json, xml. C# Web Basics - HTTP protocol, sessions, cookies, MVC, State management, Asynchronous processing. ASP.NET Core MVC - Razor, Web API, Testing, AJAX."
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Markdown & Html supported blog.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 2,
      title: 'Installing NodeJS on your device.',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/installing-nodejs-on-your-device.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 3,
      title: 'UI/UX design starter with Adobe XD.',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 4,
      title: 'Boost your post for increasing sales.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/boost-your-post-for-increasing-sales.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 5,
      title: 'Difference between GatsbyJS & NextJS.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/difference-between-gatsbyjs-and-nextjs.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 6,
      title: 'How to choose javascript framework for project.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/how-to-choose-javascript-framework-for-project.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 7,
      title: 'Web automation with python language.',
      featuredImage: '/images/blog-image-7.jpg',
      filesource: '../../blog/web-automation-with-python-language.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 8,
      title: 'Time to use latest technology for creating a website.',
      featuredImage: '/images/blog-image-8.jpg',
      filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 9,
      title: 'Think out of the box.',
      featuredImage: '/images/blog-image-9.jpg',
      filesource: '../../blog/think-out-of-the-box.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 10,
      title: 'Trending designs in 2020.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/trending-designs-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 11,
      title: 'How to get 10k instagram followers?',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/how-to-get-10k-instagram-followers.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 12,
      title: 'What NodeJS can do?',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/what-nodejs-can-do.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 13,
      title: 'Futures of javascript.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/future-of-javascript.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 14,
      title: 'Popular javascript library in 2020.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/popular-javascript-library-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 15,
      title: 'Promrammers must read books.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/programmers-must-read-books.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    phoneNumbers: ['+359-8765-65-550'],
    emailAddress: ['st.konakchiev@gmail.com'],
    address: "Lulin 10, 151, Sofia, Bulgaria"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/certificates").reply(config => {
  const response = database.certificates;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});